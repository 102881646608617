import type { CommentDto, ProjectBoardDto, ProjectListDto, ProjectProductDto, ProjectProductSubmissionDto, ProjectTaskBoardDto, ProjectTaskDetailsDto, ProjectTeacherGuideDto, SubmitProjectStageDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CommentCreateDto, CommentParticipantCandidatesDto, ProjectListRequestDto } from '../common-contracts/projects/models';

@Injectable({
  providedIn: 'root',
})
export class ProjectBoardService {
  apiName = 'Default';


  createComment = (projectId: string, taskId: string, input: CommentCreateDto) =>
    this.restService.request<any, CommentDto>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/comments`,
      body: input,
    },
    { apiName: this.apiName });


  createTaskResource = (projectId: string, taskId: string, resourceId: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/resources`,
      params: { resourceId },
    },
    { apiName: this.apiName });


  deleteComment = (projectId: string, taskId: string, commentId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/comments/${commentId}`,
    },
    { apiName: this.apiName });


  getGuides = (projectId: string) =>
    this.restService.request<any, ProjectTeacherGuideDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/guides`,
    },
    { apiName: this.apiName });


  getList = (input: ProjectListRequestDto) =>
    this.restService.request<any, PagedResultDto<ProjectListDto>>({
      method: 'GET',
      url: '/api/app/projects',
      params: { projectStatus: input.projectStatus, schoolClassId: input.schoolClassId, skipCount: input.skipCount, maxResultCount: input.maxResultCount, filter: input.filter, curriculumId: input.curriculumId, curriculumEducationStageId: input.curriculumEducationStageId, projectCategoryId: input.projectCategoryId, focusAreaId: input.focusAreaId, needsKit: input.needsKit },
    },
    { apiName: this.apiName });


  getProject = (projectId: string) =>
    this.restService.request<any, ProjectBoardDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}`,
    },
    { apiName: this.apiName });


  getProjectProductSubmissions = (projectId: string, productId: string) =>
    this.restService.request<any, ProjectProductSubmissionDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/products/${productId}/submissions`,
    },
    { apiName: this.apiName });


  getProjectTask = (projectId: string, taskId: string) =>
    this.restService.request<any, ProjectTaskDetailsDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}`,
    },
    { apiName: this.apiName });


  getProjectTasks = (projectId: string) =>
    this.restService.request<any, ProjectTaskBoardDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks`,
    },
    { apiName: this.apiName });


  getTaskCommentParticipantCandidates = (projectId: string, taskId: string) =>
    this.restService.request<any, CommentParticipantCandidatesDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/comments/ParticipantCandidates`,
    },
    { apiName: this.apiName });


  submitProjectStageByProjectIdAndStageId = (projectId: string, stageId: string) =>
    this.restService.request<any, ProjectProductDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/stages/${stageId}`,
    },
    { apiName: this.apiName });


  submitProjectStageByProjectIdAndStageIdAndInputAndFiles = (projectId: string, stageId: string, input: SubmitProjectStageDto, files: any[]) =>
    this.restService.request<any, ProjectBoardDto>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/stages/${stageId}`,
      body: input,
    },
    { apiName: this.apiName });


  updateActivityStatus = (projectId: string, taskId: string, activityId: string, isCompleted: boolean) =>
    this.restService.request<any, ProjectTaskDetailsDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/activities/${activityId}`,
      params: { isCompleted },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
