import { mapEnumToOptions } from '@abp/ng.core';

export enum ProjectStageStatus {
  InProgress = 0,
  DoneOnTime = 1,
  DoneWithDelay = 2,
  Overdue = 3,
}

export const projectStageStatusOptions = mapEnumToOptions(ProjectStageStatus);
