import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Injectable} from '@angular/core';
import {NotificationService} from '@proxy/notifications';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import {NotificationTemplates} from '../../consts/notification.templates.enum';
import {Router} from '@angular/router';
import {NotificationStoreService} from './notification-store.service';
import {NotificationData} from "../../consts/notification";
import {ReplaySubject, takeUntil} from "rxjs";
import UtilityTools from "../../utilities/utility-tools";

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  public destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isClicked: boolean = false;

  constructor(public notificationService: NotificationService,
              private afMessaging: AngularFireMessaging,
              public nzNotificationService: NzNotificationService,
              public notificationStore: NotificationStoreService,
              private router: Router) {
  }

  requestPermission() {
    this.afMessaging.requestToken
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (token) => {
          if (token) {
            this.notificationService.setRegistrationToken({token: token})
              .pipe(takeUntil(this.destroyed$))
              .subscribe();
          }
        },
        (error) => {
          console.error(error);
        },
      );
  }

  listen() {
    this.afMessaging.messages
      .subscribe((message) => {
        console.log(message);
        this.show(message);
      });
  }

  remove(id: any) {
    this.nzNotificationService.remove(id);
  }

  show(response: firebase.messaging.MessagePayload) {
    let headers = response.notification;
    let data = response.data as NotificationData;
    let requireAction = Boolean(data.RequiresAction.toLowerCase());
    let notification = this.nzNotificationService.info(headers.title, headers.body, {
      nzStyle: {
        cursor: requireAction ? 'pointer' : 'none'
      },
    });
    notification.onClick
      .pipe(takeUntil(this.destroyed$))
      .subscribe(click => {
        let element = click.srcElement as any;
        if ('viewportElement' in element)
          return;
        this.setIsClicked();
        this.click(requireAction, data, notification);
      });

    notification.onClose
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.isClicked) {
          this.notificationStore.seen(data.NotificationId);
        } else {
          this.notificationStore.setBadge(true);
        }
      });
  }

  public click(requireAction: boolean, data: NotificationData, notificationRef: any = null) {
    if (data.Action === "") return;
    data.Action = `${UtilityTools.getExactedUrl(data.Action)}`;
    switch (data.NotificationDefinition) {
      case NotificationTemplates.StudentMentioned:
      case NotificationTemplates.TeacherMentioned:
      case NotificationTemplates.ProjectTaskResourcesAdded:
        this.goToLink(data, notificationRef, true);
        break;
      default:
        this.goToLink(data, notificationRef);
        break;
    }
  }

  goToLink(data: NotificationData, notificationRef, openTask: boolean = false) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([data.Action]).then((result) => {
        this.clear(result, notificationRef);
        if (openTask) {
          this.notificationStore.popOverVisible = false;
          this.openTask(data);
        }
      });
    });

  }

  public openTask(data: NotificationData) {
    localStorage.setItem('taskId', data.TaskId);
  }

  public clear(result: any, notificationRef: { messageId: any }) {
    if (result && notificationRef !== null)
      // CALLBACK : remove notification after succeeded navigate
      this.remove(notificationRef.messageId);
  }

  public fallback() {
    this.router.navigateByUrl("/").then();
    return;
  }

  setIsClicked() {
    this.isClicked = true;
  }

}
