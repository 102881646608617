import type { ClassProjectMissingDepartment, MasterProjectToAssignDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AssignProjectDto } from '../common-contracts/projects/models';

@Injectable({
  providedIn: 'root',
})
export class ProjectAssignService {
  apiName = 'Default';
  

  assignProject = (input: AssignProjectDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/project-assign/assign-project',
      body: input,
    },
    { apiName: this.apiName });
  

  getMasterProject = (masterProjectId: string) =>
    this.restService.request<any, MasterProjectToAssignDto>({
      method: 'GET',
      url: `/api/app/project-assign/master-project/${masterProjectId}`,
    },
    { apiName: this.apiName });
  

  getTeachersAutoCheck = (masterProjectId: string, schoolClassId: string) =>
    this.restService.request<any, ClassProjectMissingDepartment[]>({
      method: 'GET',
      url: '/api/app/project-assign/teachers-auto-check',
      params: { masterProjectId, schoolClassId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
