import {AccountConfigModule} from '@abp/ng.account/config';
import {CoreModule, SessionStateService} from '@abp/ng.core';
import {registerLocale} from '@abp/ng.core/locale';
import {IdentityConfigModule} from '@abp/ng.identity/config';
import {SettingManagementConfigModule} from '@abp/ng.setting-management/config';
import {TenantManagementConfigModule} from '@abp/ng.tenant-management/config';
import {ThemeBasicModule} from '@abp/ng.theme.basic';
import {ThemeSharedModule} from '@abp/ng.theme.shared';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ar_EG, en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AbpOAuthModule} from '@abp/ng.oauth';
import {AngularFireModule} from "@angular/fire/compat";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {SpinnerComponent} from "./@theme/components/spinner/spinner.component";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeBasicModule.forRoot(),

  ],
  declarations: [AppComponent, SpinnerComponent],
  providers: [
    NzNotificationService,
    {
      provide: NZ_I18N,
      useFactory: (localeService: SessionStateService) => {
        if (localeService.getLanguage() === 'ar') return ar_EG;
        else if (localeService.getLanguage() === 'en') return en_US;
      },
      deps: [SessionStateService],
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent],
  exports: [SpinnerComponent],
})
export class AppModule {
}
