import {AuthService, ReplaceableComponentsService} from '@abp/ng.core';
import {EmptyLayoutComponent, eThemeBasicComponents} from '@abp/ng.theme.basic';
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NzConfigService} from 'ng-zorro-antd/core/config';
import {FirebaseMessagingService} from "./@core/services/notifications/firebase-messaging.service";
import {NotificationStoreService} from "./@core/services/notifications/notification-store.service";
import UtilityTools from "./@core/utilities/utility-tools";

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar [color]="'#ff8888'"></abp-loader-bar>
    <app-spinner></app-spinner>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})

export class AppComponent implements OnInit {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private router: Router,
    private readonly nzConfigService: NzConfigService,
    public firebaseMessagingService: FirebaseMessagingService,
    public notificationStore: NotificationStoreService,
    public authService: AuthService
  ) {
    this.nzConfigService.set('notification', {
      nzAnimate: true,
      nzDuration: 3000,
      nzPauseOnHover: true
    });
    this.getLayout(EmptyLayoutComponent);
  }

  ngOnInit(): void {
    UtilityTools.clearStorage();
    if (this.authService.isAuthenticated) {
      this.firebaseMessagingService.requestPermission();
      this.firebaseMessagingService.listen();
      this.notificationStore.getNotifications();
    }
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }


  public getLayout(replaceableComponent) {
    this.replaceableComponents.add({
      component: replaceableComponent,
      key: eThemeBasicComponents.ApplicationLayout,
    });
  }


}
