import { Component, Input, OnDestroy, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { Spinkit } from './spinkits';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [
    './spinkit-css/sk-line-material.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy, OnInit {
  public isSpinnerVisible = true;
  public Spinkit = Spinkit;
  @Input() public backgroundColor = 'rgba(70, 128, 255, 0.69)';
  @Input() public spinner = Spinkit.skLine;
  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {}
  ngOnInit(): void {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
