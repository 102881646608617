import {Injectable} from '@angular/core';
import {NotificationService} from '@proxy/notifications';
import {BehaviorSubject, Observable, ReplaySubject, takeUntil} from 'rxjs';
import {NotificationDto} from "@proxy/common-contracts/notifications";

@Injectable({
  providedIn: 'root'
})
export class NotificationStoreService {
  public destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  popOverVisible: boolean = false;

  constructor(public notificationService: NotificationService) {
    this.notifications$ = this.notificationsSubject.asObservable();
    this.badge$ = this.badgeSubject$.asObservable();
  }

  public badge: boolean = null;
  public badgeSubject$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public badge$: Observable<boolean>;

  public currentPage: number = 0;
  public skipCount: number = 0;
  public totalCount: number = 0;
  public pageSize: number = 10;

  public notifications: NotificationDto[] = [];
  public notificationsSubject: BehaviorSubject<NotificationDto[]> = new BehaviorSubject([]);
  public notifications$: Observable<NotificationDto[]>;

  public setBadge(value: boolean) {
    this.badge = value;
    this.badgeSubject$.next(value);
  }

  clear() {
    this.notifications = [];
    this.currentPage = 0;
    this.skipCount = 0;
    this.notificationsSubject.next(this.notifications);
  }

  next() {
    let to = this.currentPage * this.pageSize < this.totalCount ? this.currentPage * this.pageSize : this.totalCount;
    if (to === this.totalCount) return;
    this.skipCount = to;
    this.getNotifications(true);
  }

  public getNotifications(append: boolean = false) {
    this.notificationService.getNotifications({
      maxResultCount: this.pageSize,
      skipCount: this.skipCount
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notifications => {
        if (append)
          this.notifications = [...this.notifications, ...notifications.items];
        else
          this.notifications = notifications.items;
        this.totalCount = notifications.totalCount;
        this.notificationsSubject.next(this.notifications);
        this.setBadge(notifications.hasUnseenNotifications);
      });
  }

  get notSeen() {
    return this.notifications.some(a => !a.isSeen);
  }

  setAllAsSeen() {
    this.notificationService.setAllNotificationsAsSeen()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getNotifications();
      })
  }

  seen(id: any) {
    this.notificationService.setNotificationAsSeen(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getNotifications();
      });
  }
}



